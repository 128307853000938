import React from "react";
import logo from "./assets/images/fortpixel-logo.png";
import "./App.css";

function App() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-between bg-black text-white">
      <div className="flex-grow flex flex-col items-center justify-center">
        {/* FortPixel Studios Logo */}
        <div className="mb-8">
          <img src={logo} alt="FortPixel Studios Logo" className="mx-auto" />
        </div>

        {/* Coming soon message */}
        <h1 className="text-4xl font-bold mb-4 text-fortpixel-gold">
          Coming Soon
        </h1>
      </div>

      {/* Footer */}
      <footer className="w-full text-center py-4">
        <p className="text-sm text-gray-400">
          © 2024 FortPixel Studios. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default App;
